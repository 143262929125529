import React from 'react';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

function SIPDaySelect(props) {

    const dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

    return <FormControl fullWidth style={{ marginTop: "10px", marginBottom: "20px" }}>
        <InputLabel id="sip_date">Monthly SIP Date</InputLabel>
        <Select
            labelId="sip_date"
            id="sip_date"
            fullWidth
            value={props.value}
            onChange={props.handleChange}
        >
            {
                dates.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                })
            }
        </Select>
    </FormControl>
}

export default SIPDaySelect;