import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NavigationBar from './Components/NavigationBar';
import Home from './Pages/Home';
import SigninPage from './Pages/Signin';
import Signup from './Pages/Signup';
import AuthContext from './Contexts/AuthContext';
import MFContexts from './Contexts/MFContexts';
import AllMFSNamesContext from './Contexts/AllMFSNamesContext';
import AddMFPage from './Pages/AddMFPage';
import SIPCalculator from './Pages/SIPCalculator';

function App() {
  const token = localStorage.getItem('token');
  const [currentUser, setCurrentUser] = React.useState(null);
  const [contextLoading, setContextLoading] = React.useState(false);
  const [mfs, setMfs] = React.useState(null);
  const [allMFS, setAllMFS] = React.useState([]);
  const value = { currentUser, setCurrentUser };
  const mfsvalue = { mfs, setMfs, contextLoading, setContextLoading };
  const allMFSValue = { allMFS, setAllMFS };

  React.useEffect(() => {
    if (token) {
      fetch(process.env.REACT_APP_API_URL + '/get_user', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        method: "GET"
      }).then((response) => {
          if (response.status === 200) {
            response.json().then(value => {
              setCurrentUser(value);
            })
          }
          else {
            setCurrentUser(null);
            localStorage.removeItem('token')
          }
      })
    }
  }, [token])

  React.useEffect(() => {
    setContextLoading(true)
    if (token && currentUser) {
      fetch(process.env.REACT_APP_API_URL + '/user/get_mfs?id=' + currentUser._id, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        method: "GET"
      }).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            setMfs(data)
          })
        }
        setContextLoading(false)
      })
    }
  }, [currentUser, token])

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/get_mfs', {
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      method: "GET"
    }).then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          setAllMFS(data);
        })
      }
    })
  }, [])


  return (
    <Router>
      <div>
        <Switch>
          <AuthContext.Provider value={value}>
            <AllMFSNamesContext.Provider value={allMFSValue}>
              <MFContexts.Provider value={mfsvalue}>
                <NavigationBar>
                  <Route exact path="/" component={Home}></Route>
                  <Route exact path="/signin" component={SigninPage}></Route>
                  <Route exact path="/signup" component={Signup}></Route>
                  <Route exact path="/addmf" component={AddMFPage}></Route>
                  <Route exact path="/calc" component={SIPCalculator}></Route>
                </NavigationBar>
              </MFContexts.Provider>
            </AllMFSNamesContext.Provider>
          </AuthContext.Provider>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
