import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, OutlinedInput, Typography } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';



const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4)
    },
    margin: {
        margin: theme.spacing(1),
        width: "100px"
    },
}));

function SipCalculatorDiv(props) {

    const classes = useStyles();

    const [investedAmount, setInvestedAmount] = React.useState(10000);
    const [estimatedReturns, setEstimatedReturns] = React.useState(0);
    const [returnRate, setReturnRate] = React.useState(12);
    const [timePeriod, setTimePeriod] = React.useState(2);

    React.useEffect(()=>{
        const i = returnRate/(12*100)
        const c = Number(investedAmount)*Number(Number(Number(((1+i)**(Number(timePeriod)*12))-1)/i)*(1+Number(i)))
        setEstimatedReturns(c)
    },[investedAmount,returnRate,timePeriod])


    const handleAmountChange = (e) => {
        setInvestedAmount(e.target.value);
    }

    const handleReturnRateChange = (e) => {
        setReturnRate(e.target.value);
    }

    const handleTimePeriod = (e) => {
        setTimePeriod(e.target.value);
    }


    return (
        <div style={{ width: '100%' }}>
            <FormControl fullWidth >
                <InputLabel htmlFor="standard-adornment-amount">Monthly investment</InputLabel>
                <Input
                    fullWidth
                    id="standard-adornment-amount"
                    value={investedAmount}
                    onChange={handleAmountChange}
                    startAdornment={<InputAdornment position="start">₹​</InputAdornment>}
                />
            </FormControl>
            <Box display="flex" style={{ marginTop: "20px" }}>
                <Box flexGrow={1} >
                    <Typography>Expected Return Rate</Typography>
                </Box>
                <Box>
                    <FormControl variant="outlined" fullWidth className={classes.margin}>
                        <OutlinedInput
                            fullWidth
                            value={returnRate}
                            onChange={handleReturnRateChange}
                            endAdornment={<InputAdornment position="start">%</InputAdornment>}>
                        </OutlinedInput>
                    </FormControl>
                </Box>
            </Box>
            <Box display="flex">
                <Box flexGrow={1}><Typography>Time Period</Typography></Box>
                <Box>
                    <FormControl fullWidth className={classes.margin}>
                        <OutlinedInput
                            fullWidth
                            value={timePeriod}
                            onChange={handleTimePeriod}
                            endAdornment={<InputAdornment position="start">yr</InputAdornment>}>
                        </OutlinedInput>
                    </FormControl>
                </Box>
            </Box>
            <Box display="flex" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Typography color="textSecondary">Invested Amount - ₹​</Typography>
                <Typography >{Number(investedAmount*timePeriod*12).toLocaleString('en-IN')}</Typography>
            </Box>
            <Box display="flex">
                <Typography color="textSecondary">Estimated Returns - ₹​</Typography>
                <Typography >{Number(estimatedReturns.toFixed(0)-(investedAmount*timePeriod*12)).toLocaleString('en-IN')}</Typography>
            </Box>
            <Box display="flex" style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Typography color="textSecondary">Estimated Value - ₹​</Typography>
                <Typography >{"  "+Number(estimatedReturns.toFixed(0)).toLocaleString('en-IN')}</Typography>
            </Box>
        </div>
    )
}

export default SipCalculatorDiv;