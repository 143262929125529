import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CssBaseline from '@material-ui/core/CssBaseline';

import AlertToast from '../Components/AlertToast';
import LumpsumCalculatorDiv from '../Components/LumpsumCalculatorDiv';
import SipCalculatorDiv from '../Components/SIPCalculatorDiv';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(5),
        marginTop: theme.spacing(2),
        width: '100%'
    },
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));


export default function SIPCalculator(props) {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: 'success',
        type: 'error'
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAlertClose = () => {
        setState({ ...props.state, open: false })
        props.history.push("/")
    };

    return (
        <Container component="main"  maxWidth="xs">
            <AlertToast state={state} setState={setState} handleClose={handleAlertClose}></AlertToast>
            <CssBaseline></CssBaseline>
            <div className={classes.paper}>
                <Paper className={classes.root} elevation={0}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="  SIP  " />
                        <Tab label="LUMPSUM" />
                    </Tabs>
                </Paper>
                {value === 0 && <SipCalculatorDiv></SipCalculatorDiv>}
                {value === 1 && <LumpsumCalculatorDiv></LumpsumCalculatorDiv>}
            </div>
        </Container>
    );
}
