import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function HomeCard(props) {
  const classes = useStyles();
  const mf = props.mf;
  const [mfData,setMfData] = React.useState(null);
  var date = new Date(mf.invested_on)

  React.useEffect(()=>{
    fetch(process.env.REACT_APP_API_URL+'/user/get_mf_data?id='+mf.scheme_code, {
      method: "GET",
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    }).then((response)=> {
      if(response.status === 200){
        // console.log(response.data)
        response.json().then(val=>{
          setMfData(val);
        })
      }
    })
  },[mf])

  return (
    <div>
      {
        !mfData && <div>
          <Skeleton variant="rect" animation="wave" height={100} />
          <Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></div>
      }
      {
        mfData && <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {"Scheme Code - "+mf.scheme_code}
          </Typography>
          <Typography variant="h5" component="h2">
            {mfData.scheme_name}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {"Invested amount - "+ mf.invested_amount}
          </Typography>
          {mf.investment_type === "sip"&& <React.Fragment>
          <Typography className={classes.pos} color="textSecondary">
            {"SIP Amount- "+ mf.sip_amount}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {"SIP Date - "+ mf.sip_date}
          </Typography>
          </React.Fragment>}
          <Typography className={classes.pos} color="textSecondary">
            {"Started on - "+ date.toLocaleDateString()}
          </Typography>
          <Typography variant="body2" component="p">
            {"Current Value - "+(mf.alloted_units*mfData.nav).toFixed(2)}
            <br />
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
      }
    </div>
  );
}