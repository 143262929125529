import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthContext from '../Contexts/AuthContext';
import AlertToast from '../Components/AlertToast';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function SignUp(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const { setCurrentUser } = React.useContext(AuthContext);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: 'success',
        type: 'error'
    });

    const token = localStorage.getItem("token");

    if (token) {
        return <Redirect to="/"></Redirect>
    }

    const handleSignup = (e) => {
        e.preventDefault();
        setLoading(true)
        const { name, email, password } = e.target.elements;
        const payload = {
            name: name.value,
            email: email.value,
            password: password.value,
        };
        fetch(process.env.REACT_APP_API_URL + '/signup', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === 200) {
                response.json().then(value => {
                    
                    localStorage.setItem("token", value.token);
                    setCurrentUser(value.user)
                    props.history.push("/")
                    setState({
                        ...state,
                        open: true,
                        message: "Signin Successful",
                        type: "success",
                    })
                })
            }
            else if (response.status === 201) {
                setLoading(false);
                setState({
                    ...state,
                    open: true,
                    message: "User already exists, Please signin",
                    type: "error",
                })
            }
            else {
                setState({
                    ...state,
                    open: true,
                    message: "Something went wrong try again",
                    type: "error",
                })
            }
            setLoading(false);
        })
    }

    const handleSigninButton = (e) => {
        e.preventDefault();
        props.history.push("/signin")
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <AlertToast state={state} setState={setState}></AlertToast>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form} onSubmit={handleSignup}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="fname"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Full Name"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress color="primary" size={24} /> : "Sign Up"}
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link onClick={handleSigninButton} variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={5}>
                {/* <Copyright /> */}
            </Box>
        </Container>
    );
}

export default withRouter(SignUp);