import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CircularProgress } from '@material-ui/core';

import AuthContext from '../Contexts/AuthContext';
import MFsContext from '../Contexts/MFContexts';
import AllMFSNamesContext from '../Contexts/AllMFSNamesContext';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4)
    }
}));

function LumpsumDiv(props) {

    const classes = useStyles();

    const token = localStorage.getItem('token');
    const { currentUser } = React.useContext(AuthContext);
    const { allMFS } = React.useContext(AllMFSNamesContext);
    const { setMfs } = React.useContext(MFsContext);

    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [investedAmount, setInvestedAmount] = React.useState(0);
    const [schemeCode, setSchemeCode] = React.useState(null);
    const [loading, setLoading] = React.useState(false);



    const handleDateChange = (date) => {
        setSelectedDate(date);
    };


    const handleSchemeChange = (e, val) => {
        if (val) {
            setSchemeCode(val.scheme_code);
        }
    }

    const handleAmountChange = (e) => {
        console.log(e.target.value)
        setInvestedAmount(e.target.value);
    }

    const handleSubmit = () => {
        setLoading(true);
        const payload = {
            scheme_code: schemeCode,
            invested_on: selectedDate,
            invested_amount: investedAmount,
            user_id: currentUser._id,
            investment_type: "lumpsum"
        }
        fetch(process.env.REACT_APP_API_URL + '/user/add_mf', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === 200) {
                response.json().then(val => {
                    setMfs(val)
                })
                props.setState({
                    ...props.state,
                    open: true,
                    message: "Successful",
                    type: "success",
                })
            }
            else {
                props.setState({
                    ...props.state,
                    open: true,
                    message: "Something went wrong try again",
                    type: "error",
                })
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <Autocomplete
                id="mutual fund"
                margin="dense"
                fullWidth
                options={allMFS}
                getOptionLabel={(option) => option.scheme_name}
                onChange={handleSchemeChange}
                renderInput={(params) => <TextField {...params} label="Mutual Fund" variant="standard" />}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} required >
                <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="invested-date"
                    label="Invested on"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'invested date',
                    }}
                />
            </MuiPickersUtilsProvider>
            <TextField
                id="amount"
                fullWidth
                label="Invested Amount"
                type="number"
                onChange={handleAmountChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                className={classes.button}
                disabled={loading}
                color="primary">
                {loading ? <CircularProgress color="primary" size={24} /> : "ADD"}
            </Button>
        </div>
    )
}

export default LumpsumDiv;