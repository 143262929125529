import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import AuthContext from '../Contexts/AuthContext';
import AlertToast from '../Components/AlertToast';



// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {'Copyright © '}
//             <Link color="inherit" href="https://mftracker.eastus.cloudapp.azure.com">
//                 MF TRACKER
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Signin(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const token = localStorage.getItem("token");
    const { setCurrentUser } = React.useContext(AuthContext);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: 'success',
        type: 'error'
    });

    if (token) {
        return <Redirect to="/"></Redirect>
    }

    const handleSignin = (e) => {
        e.preventDefault();
        setLoading(true)
        const { email, password } = e.target.elements;
        const payload = {
            email: email.value,
            password: password.value,
        };
        fetch(process.env.REACT_APP_API_URL+'/signin', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === 200) {
                response.json().then(value => {
                    
                    localStorage.setItem("token", value.token);
                    
                    setCurrentUser(value.user);
                    props.history.push("/")
                    setState({
                        ...state,
                        open: true,
                        message: "Signin Successful",
                        type: "success",
                    })
                })
            }
            else if (response.status === 201) {
                setLoading(false);
                setState({
                    ...state,
                    open: true,
                    message: "Invalid Credentials",
                    type: "error",
                })
            }
            else{
                setState({
                    ...state,
                    open: true,
                    message: "Something went wrong try again",
                    type: "error",
                })
            }
            setLoading(false);
        })
    }

    const handleSignup = (e) => {
        e.preventDefault();
        props.history.push("/signup")
    }

    return (
        <Container component="main" maxWidth="xs">
            <AlertToast state={state} setState={setState}></AlertToast>
            <CssBaseline />
            <div className={classes.paper}>
            
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={handleSignin}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.submit}
                    >
                        {loading ? <CircularProgress color="primary" size={24} /> : "Sign In"}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link onClick={handleSignup} variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                {/* <Copyright /> */}
            </Box>
        </Container>
    );
}

export default withRouter(Signin)