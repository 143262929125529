import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { CircularProgress, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AuthContext from '../Contexts/AuthContext';
import MFsContext from '../Contexts/MFContexts';
import AllMFSNamesContext from '../Contexts/AllMFSNamesContext';
import SIPDaySelect from './SIPDaySelect';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(4)
    }
}));

function SIPDiv(props) {

    const classes = useStyles();

    const token = localStorage.getItem('token');
    const { currentUser } = React.useContext(AuthContext);
    const { allMFS } = React.useContext(AllMFSNamesContext);
    const { setMfs } = React.useContext(MFsContext);

    const [hasInitialInvestment, setHasInitialInvestment] = React.useState(false);
    const [intialInvestedAmount, setInitialInvestedAmount] = React.useState(0);
    const [initialInvestmentDate, setInitialInvestmentDate] = React.useState(new Date());
    const [schemeCode, setSchemeCode] = React.useState(null);
    const [sipAmount,setSipAmount] = React.useState(0);
    const [sipDate,setSipDate] = React.useState(1);
    const [sipStartDate,setSIPStartDate] = React.useState(new Date());
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event) => {
        setHasInitialInvestment(event.target.checked);
    }

    const handleDateChange = (date) => {
        setInitialInvestmentDate(date);
    };


    const handleSchemeChange = (e, val) => {
        if (val) {
            setSchemeCode(val.scheme_code);
        }
    }

    const handleInitalAmountChange = (e) => {
        setInitialInvestedAmount(e.target.value);
    }

    const handleSipDateChange = (e)=>{
        setSipDate(e.target.value)
    }

    const handleSipAmountChange = (e) => {
        setSipAmount(e.target.value)
    }

    const handleSIPStartDate = (val) => {
        setSIPStartDate(val)
    }

    const handleSubmit = () => {
        setLoading(true);
        const payload = {
            scheme_code: schemeCode,
            invested_on: hasInitialInvestment?initialInvestmentDate:sipStartDate,
            // invested_amount: investedAmount,
            user_id: currentUser._id,
            investment_type: "sip",
            sip_date: sipDate,
            sip_amount: sipAmount,
            has_inital_investment: hasInitialInvestment,
            initial_investment_amount: intialInvestedAmount,

        }
        fetch(process.env.REACT_APP_API_URL + '/user/add_sip_mf', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === 200) {
                response.json().then(val => {
                    setMfs(val)
                })
                props.setState({
                    ...props.state,
                    open: true,
                    message: "Successful",
                    type: "success",
                })
            }
            else {
                props.setState({
                    ...props.state,
                    open: true,
                    message: "Something went wrong try again",
                    type: "error",
                })
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <Autocomplete
                id="mutual fund"
                margin="dense"
                fullWidth
                options={allMFS}
                getOptionLabel={(option) => option.scheme_name}
                onChange={handleSchemeChange}
                renderInput={(params) => <TextField {...params} label="Mutual Fund" variant="standard" />}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hasInitialInvestment}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                    />
                }
                label="Initial investment"
            />
            {hasInitialInvestment && <div style={{ border: '1px solid grey', padding: '10px', margin: '1px', marginTop: '10px', borderRadius: '20px' }}>
                <Typography>Initial investment(optional)</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils} required >
                    <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="invested-date"
                        label="Invested on"
                        format="dd/MM/yyyy"
                        value={initialInvestmentDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'invested date',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <TextField
                    id="amount"
                    fullWidth
                    label="Invested Amount"
                    type="number"
                    onChange={handleInitalAmountChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>}
            <SIPDaySelect value={sipDate} handleChange={handleSipDateChange}></SIPDaySelect>
            <TextField
                id="amount"
                variant="outlined"
                fullWidth
                label="SIP Amount"
                type="number"
                onChange={handleSipAmountChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {!hasInitialInvestment && <MuiPickersUtilsProvider utils={DateFnsUtils} required >
                    <KeyboardDatePicker
                        fullWidth
                        margin="normal"
                        id="invested-date"
                        label="SIP Start Date"
                        format="dd/MM/yyyy"
                        value={sipStartDate}
                        onChange={handleSIPStartDate}
                        KeyboardButtonProps={{
                            'aria-label': 'invested date',
                        }}
                    />
                </MuiPickersUtilsProvider>}
            <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                className={classes.button}
                disabled={loading}
                color="primary">
                {loading ? <CircularProgress color="primary" size={24} /> : "ADD"}
            </Button>
        </div>
    )
}

export default SIPDiv;