import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import HomeCard from '../Components/HomeCard';
// import AddMFDialog from '../Components/AddMFDialog';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// import AuthContext from '../Contexts/AuthContext';
import MFsContext from '../Contexts/MFContexts';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        marginTop: theme.spacing(1)
    },
    button: {
        fullWidth: true,
        borderRadius: theme.spacing(1),
        margin: theme.spacing(2),
        width: "80%"
    },
    rightPanel: {
        position: 'sticky',
        margin: theme.spacing(1),
        height: '89vh',
        top: theme.spacing(10),
        backgroundColor: theme.palette.primary.light,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    subRpaper: {
        position: 'relative',
        overflow: 'auto',
        maxHeight: '89vh',
        backgroundColor: theme.palette.primary.light
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 10,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    deskGrid: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobileGrid: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
}));

function Home(props) {
    const classes = useStyles();

    const token = localStorage.getItem("token");
    // const { currentUser } = React.useContext(AuthContext);
    const {mfs,contextLoading} = React.useContext(MFsContext);

    if (!token) {
        return <Redirect to="/signin"></Redirect>
    }

    const handleAddButton = () => {
        props.history.push("/addmf")
    }

    return (
        <div className={classes.grow}>
            <Grid container component="main">
                <Grid item xs={12} md={2}>
                </Grid>
                <Grid item xs={12} md={8}>
                    {!contextLoading && mfs && <Grid container spacing={1} className={classes.deskGrid}>
                        {
                            mfs.map(umf => {
                                return <Grid item xs={12} md={6} key={umf._id}>
                                    <HomeCard mf={umf}></HomeCard>
                                </Grid>
                            })
                        }
                    </Grid>}
                    {!contextLoading && mfs && <Grid container className={classes.mobileGrid}>
                        {
                            mfs.map(umf => {
                                return <Grid item xs={12} md={6} key={umf._id}>
                                    <HomeCard mf={umf}></HomeCard>
                                </Grid>
                            })
                        }
                    </Grid>}
                    {
                        contextLoading && <div>
                        <Skeleton variant="rect" animation="wave" height={118} />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <br></br><br></br>
                        <Skeleton variant="rect" animation="wave" height={118} />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </div>
                    }
                    {
                        !contextLoading && mfs && mfs.length===0 && <Typography>No MFS Found</Typography>
                    }
                </Grid>
                <Grid item xs={12} md={2}>
                    <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleAddButton}>
                        <AddIcon />
                    </Fab>
                    <Paper className={classes.rightPanel} elevation={0}>
                        <Paper className={classes.subRpaper} elevation={0}>
                            <Button
                                onClick={handleAddButton}
                                variant="contained"
                                color="primary"
                                className={classes.button}>
                                Add
                            </Button>
                        </Paper>
                    </Paper>
                </Grid>
            </Grid>
            {/* <AddMFDialog open={openAddDialog} setOpen={setOpenDialog} mfs={allMFS}></AddMFDialog> */}
        </div>
    )
}
export default withRouter(Home);